import AttendeeSubscribe from '@components/core/AttendeeSubscribe';
import { Container, ImageBanner, FormContainer, Border } from './styles';

const BannerForm: React.FC = () => {
  return (
    <Border>
      <Container>
        <ImageBanner />
        <FormContainer>
          <AttendeeSubscribe
            event="innovationpay-2021"
            loading={<div>Carregando formulário...</div>}
            portalId="3296805"
            formId="27080e34-332b-48ef-bdf1-903225fcc161"
            redirect="https://www.startse.com/innovation-pay-inscricao-confirmada/"
          />
        </FormContainer>
      </Container>
    </Border>
  );
};

export default BannerForm;
