import { styled } from '@material-ui/core/styles';

export const Border = styled('div')(() => ({
  border: 'solid 20px #fff',
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.16)',
  borderRadius: '10px',
  display: 'flex',
  flexWrap: 'wrap',
  margin: '20px auto',
  width: '100%',
}));

export const Container = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  borderRadius: '10px',
  backgroundColor: '#000',
  '@media screen and (min-width: 960px)': {
    justifyContent: 'space-between',
    minHeight: '600px',
    width: 'calc(100vw - 6.1em - 120px)',
    flexWrap: 'nowrap',
  },
}));

export const ImageBanner = styled('div')(() => ({
  backgroundImage:
    'url(https://startse-landings.s3.us-east-2.amazonaws.com/innovation-pay-2021/img/iframe-banner-mobile.png?q)',
  backgroundRepeat: 'no-repeat',
  height: '760px',
  width: '100%',
  backgroundPosition: 'center top',
  backgroundSize: 'contain',
  '@media screen and (min-width: 960px)': {
    backgroundImage:
      'url(https://startse-landings.s3.us-east-2.amazonaws.com/innovation-pay-2021/img/iframe-banner-desktop.png?q)',
    backgroundPosition: 'left center',
    height: '100%',
    flexGrow: 2,
    maxWidth: '950px',
  },
}));

export const FormContainer = styled('div')(() => ({
  marginBottom: '20px',
  '@media screen and (min-width: 960px)': {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    flexGrow: 1,
    marginBottom: '0px',
  },
}));
