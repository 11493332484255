import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  '@global': {
    '@keyframes pulsate': {
      '0%': {
        transform: 'scale(0.1, 0.1)',
        opacity: '0',
      },
      '50%': {
        opacity: '1',
      },
      '100%': {
        transform: 'scale(1.2, 1.2)',
        opacity: '0',
      },
    },
  },
  dot: {
    position: 'relative',
    width: '25px',
    height: '25px',
    marginRight: '5px',
    display: 'inline-block',
    marginBottom: '-4px',
    '& .circle': {
      width: '15px',
      height: '15px',
      backgroundColor: '#de3d1f',
      borderRadius: '50%',
      position: 'absolute',
      top: '5px',
      left: '5px',
    },
    '& .ringring': {
      border: '3px solid #de3d1f',
      borderRadius: '30px',
      height: '25px',
      width: '25px',
      left: '0px',
      top: '0px',
      position: 'absolute',
      animation: 'pulsate 1s ease-out',
      animationIterationCount: 'infinite',
      opacity: '0',
    },
  },
});

const LiveDot: React.FC = () => {
  const style = useStyles();

  return (
    <div className={style.dot}>
      <div className="ringring" />
      <div className="circle" />
    </div>
  );
};

export default LiveDot;
