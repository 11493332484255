import { useEffect } from 'react';
import HubspotForm from 'react-hubspot-form';

import { useTracking } from '@hooks/tracking';
import { splitName } from '@utils/format';
import * as Styled from './styled';

interface AttendeeSubscribeProps {
  portalId?: string;
  formId?: string;
  redirect?: string;
  loading?: any;
  event?: string;
}

const AttendeeSubscribe: React.FC<AttendeeSubscribeProps> = (
  props,
): JSX.Element => {
  const { track, identify } = useTracking();
  const { portalId, formId, loading } = props;

  useEffect(() => {
    const handler = (event: any) => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmit'
      ) {
        if (event.data.id === formId) {
          const { data } = event.data;
          const fullname = data.find(prop => prop.name === 'firstname').value;
          const firstname = splitName(fullname)[0];
          const email = data.find(prop => prop.name === 'email').value;
          const { redirect } = props;

          if (fullname && email) {
            // Tracking
            identify(email);
            track('Clicked subscribe event', {
              Eventname: props.event,
              fromBanner: true,
            });
            // Viral Loops send identify
            if ((window as any).campaign)
              (window as any).campaign.identify(
                {
                  email,
                  firstname,
                },
                () => {
                  window.location.href = redirect;
                },
              );
          } else (window as any).location.href = redirect;
        }
      }
    };

    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [formId, identify, props, track]);

  return (
    <Styled.Group>
      <Styled.GlobalStyle />
      <Styled.Title>
        <Styled.Text>Inscreva-se Gratuitamente!</Styled.Text>
      </Styled.Title>
      <HubspotForm
        portalId={portalId}
        formId={formId}
        loading={loading}
        css=""
        cssClass="hubspot-attendee-form"
      />
    </Styled.Group>
  );
};

export default AttendeeSubscribe;
