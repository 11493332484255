import { Box, styled, Theme } from '@material-ui/core';

import { PrimaryButton } from '@components/core';

import Img from 'next/image';

interface ISubtitleSponsors {
  theme: Theme;
  fontColor: string;
}

export const MainTitle = styled('div')({
  fontSize: 28,
  fontWeight: 700,
  padding: '10px 0 20px',
});

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  fontFamily: 'Barlow',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 28px',
  marginTop: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
  '& p': {
    margin: 0,
  },
  '&::-webkit-scrollbar-track': {
    background: '#fff',
    border: 'solid 1px #e5e5e5',
  },
  '&::-webkit-scrollbar': {
    width: 25,
    height: 25,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#f5f5f5',
    border: 'solid 1px #cbcbcb',
    borderRadius: 4,
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

export const Wrapper = styled('div')(({ theme }) => ({
  background: '#fff',
  border: '2px solid #FFF',
  borderRadius: 13,
  boxShadow: '0 0 6px #0000001C',
  padding: '84px 65px',
  [theme.breakpoints.down('sm')]: {
    padding: '24px 12px',
  },
}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontFamily: 'Barlow',
  marginBottom: 30,
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    minHeight: 'initial',
    maxHeight: 'initial',
    width: 'initial',
    height: 'initial',
    marginBottom: 30,
  },
}));

export const BoxImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 404,
  minWidth: 404,
  height: 394,

  [theme.breakpoints.down(450)]: {
    minWidth: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    width: 195,
    height: 190,
    minHeight: '25vh',
    marginBottom: 20,
  },
}));

export const Image = styled(Img)(({ theme }) => ({
  borderRadius: 13,
  width: 404,
  height: 394,
  [theme.breakpoints.down('sm')]: {
    width: 195,
    height: 190,
  },
}));

export const ColumnEvent = styled('div')(({ theme }) => ({
  width: 'calc(100% - 500px)',
  maxWidth: 600,
  height: 394,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  marginLeft: 40,
  fontFamily: 'Barlow',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    marginTop: 5,
    marginLeft: 0,
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 28,
  width: '100%',
  fontFamily: 'Barlow',
  margin: 0,
  [theme.breakpoints.down(1100)]: {
    fontSize: 20,
  },
}));

export const Description = styled('p')(({ theme }) => ({
  fontSize: 28,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: 'Barlow',
  display: '-webkit-box',
  maxHeight: '100px',
  WebkitLineClamp: 3,
  '-webkit-box-orient': 'vertical',
  margin: 0,
  padding: '8px 0 24px',
  [theme.breakpoints.down(1600)]: {
    fontSize: 20,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 15,
  },
}));

export const Date = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 500,
  fontSize: 20,
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

export const Button = styled(PrimaryButton)(({ theme }) => ({
  width: '413px',
  height: '62px',
  fontFamily: 'Barlow',
  textTransform: 'uppercase',
  alignItems: 'center',
  fontSize: '25px',
  fontWeight: 600,
  minHeight: 'initial',
  borderRadius: '30px',
  [theme.breakpoints.down(1250)]: {
    marginTop: '20px',
    width: '222px',
    height: '33px',
    fontSize: '13px',
    padding: '0px',
  },
}));

export const ColumnTerms = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  marginLeft: -9,
  marginBottom: 24,
}));

export const RowTerms = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Terms = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: '20px',
  fontWeight: 500,
  paddingLeft: 8,
  margin: 0,

  [theme.breakpoints.down(1400)]: {
    fontSize: '16px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}));

export const UncheckIcon = styled('span')({
  width: '1em',
  height: '1em',
  border: '2px solid #acb1c0',
  borderRadius: 8,
});

export const IconContainer = styled('span')({
  width: '1em',
  height: '1em',
  backgroundColor: '#0080ed',
  bordeRadius: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Error = styled('span')({
  fontSize: 14,
  fontWeight: 500,
  color: 'red',
  paddingLeft: 9,
});

export const TitleSpeakers = styled('h3')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 28,
  margin: 0,
  lineHeight: 1.5,
  fontFamily: 'Barlow, sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: 17,
    padding: '30px 0 0',
  },
}));

export const TitleSponsors = styled('h4')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 40,
  textTransform: 'uppercase',
  justifyContent: 'center',
  margin: '0 auto',
  padding: '60px 0 20px',
  fontFamily: 'Barlow, sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: 22,
    padding: '60px 0 0',
  },
}));

export const SubtitleSponsors = styled('h4')(
  ({ theme, fontColor }: ISubtitleSponsors) => ({
    fontWeight: 500,
    fontSize: 32,
    color: fontColor,
    textTransform: 'uppercase',
    margin: 0,
    padding: '40px 0 16px',
    fontFamily: 'Barlow, sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      padding: '20px 0 0',
    },
  }),
);

export const Icon = styled(Img)(() => ({
  width: 22,
  height: 22,
}));

export const ImageSpeaker = styled(Img)(({ theme }) => ({
  borderRadius: 20,
  width: 310,
  height: 330,
  [theme.breakpoints.down('sm')]: {
    width: 126,
    height: 126,
    borderRadius: '100%',
  },
}));

export const SpeakersWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'hidden',
});

export const ImageSponsor = styled('img')(() => ({
  width: 250,
  height: 'auto',
  margin: '16px 16px 16px 0',
}));

export const SponsorsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const SponsorCta = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 30,
}));

export const TitleSponsorCta = styled('h5')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 37,
  fontFamily: 'Barlow',
  textTransform: 'uppercase',
  margin: 0,
  padding: '30px 0 20px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: 22,
    padding: '20px 0',
  },
  '& span': {
    fontFamily: 'Barlow',
    color: '#8000FF',
  },
}));

export const SubtitleSponsorCta = styled('p')(({ theme }) => ({
  fontWeight: 'normal',
  fontSize: 29,
  margin: 0,
  paddingBottom: 20,
  textAlign: 'center',
  fontFamily: 'Barlow',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
    paddingBottom: 0,
  },
}));

export const ContainerFormatData = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '20px',
  marginBottom: '10px',
  [theme.breakpoints.down(1400)]: {
    fontSize: 12,
    paddingBottom: 0,
  },
}));

export const ContainerSponsors = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));
